<template>
  <div class="main">
    <h3>HELP</h3>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.main {
  margin: 20px;
}
</style>
